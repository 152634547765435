import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import CrudList from '../components/CrudList/CrudList';

const QuestionsIndex = () => {
    const headers = [
        { key: 'id', label: 'ID', className: 'small' },
        { key: 'content', label: 'Treść', className: '' },
        { key: 'createdAt', label: 'Data', className: '' },
        { key: 'isActive', label: 'Aktywne', className: 'small', component: 'boolean' },
        { key: 'actions', label: 'Akcje', className: 'small', actions: ['edit', 'delete'] },
    ];

    return (
        <Dashboard>
            <CrudList
                pageHeader="Pytania do lek. wet."
                headers={headers}
                apiIndex="/questions"
                apiUpdate="/questions"
                apiDelete="/questions"
            />
        </Dashboard>
    );
};
export default QuestionsIndex;
