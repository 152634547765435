import http from '../Api/http';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
const allowedAdminUserTypes = [100];

export const login = (token: string) => {
    return (dispatch: any) => {
        localStorage.setItem('wp-token', token);
        http().get('/auth/current')
            .then((response) => {
                localStorage.setItem('wp-user', JSON.stringify(response.data));
                dispatch({
                    type: LOGIN,
                    isAuth: allowedAdminUserTypes.includes(response.data.userType),
                    user: response.data,
                    token,
                    currentCalled: true,
                });
            })
            .catch(() => {
                dispatch({
                    type: LOGIN,
                    isAuth: false,
                    user: null,
                    token: null,
                    currentCalled: true,
                });
            });
    };
};

export const logout = () => {
    return (dispatch: any) => {
        localStorage.removeItem('wp-user');
        localStorage.removeItem('wp-token');
        dispatch({ type: LOGOUT });
    };
};