import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import CrudList from '../components/CrudList/CrudList';

const ReviewsIndex = () => {
    const headers = [
        { key: 'id', label: 'ID', className: 'small' },
        { key: 'content', label: 'Treść', className: '' },
        { key: 'rate', label: 'Ocena', className: '', component: 'rating' },
        { key: 'createdAt', label: 'Data', className: '' },
        { key: 'isActive', label: 'Aktywna', className: 'small', component: 'boolean' },
        { key: 'actions', label: 'Akcje', className: 'small', actions: ['edit', 'delete'] },
    ];

    return (
        <Dashboard>
            {/*<h2>*/}
            {/*    Opinie (<Link to={'/reviews'}>Wszystkie</Link> / <Link to={'/reviews?is_active=true'}>Aktywne</Link> / <Link to={'/reviews?is_active=false'}>Nowe</Link>)*/}
            {/*</h2>*/}
            <CrudList
                pageHeader="Opinie"
                headers={headers}
                apiIndex="/reviews"
                apiUpdate="/reviews"
                apiDelete="/reviews"
            />
        </Dashboard>
    );
};
export default ReviewsIndex;
