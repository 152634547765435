import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import CrudList from '../components/CrudList/CrudList';

const UsersIndex = () => {
    const headers = [
        { key: 'id', label: 'ID', className: 'small' },
        { key: 'firstName', label: 'Imię', className: '' },
        { key: 'lastName', label: 'Nazwisko', className: '' },
        // { key: 'email', label: 'E-mail', className: '' },
        { key: 'isVeterinarian', label: 'Lekarz', className: 'small', component: 'boolean' },
        { key: 'isClinicOwner', label: 'Właściciel', className: 'small', component: 'boolean' },
        { key: 'isActive', label: 'Aktywny', className: 'small', component: 'boolean' },
        { key: 'actions', label: 'Akcje', className: 'small', actions: ['edit'] },
    ];

    return (
        <Dashboard>
            <CrudList
                pageHeader="Użytkownicy"
                headers={headers}
                apiIndex="/users"
                apiUpdate="/users"
                apiDelete="/users"
                pageSize={25}
            />
        </Dashboard>
    );
};
export default UsersIndex;
