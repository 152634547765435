export const vetTypes: any[] = [
    { id: 10, name: 'Gabinet' },
    { id: 20, name: 'Przychodnia' },
    { id: 30, name: 'Lecznica' },
    { id: 40, name: 'Laboratorium' },
    { id: 50, name: 'Klinika' },
];

export const fieldsConfig: any = {
    id: {
        label: 'ID',
        type: 'text',
        hidden: true,
    },
    name: {
        label: 'Nazwa',
        type: 'text',
        size: 12,
    },
    mime: {
        label: 'Typ pliku MIME',
        type: 'text',
        disabled: true,
    },
    size: {
        label: 'Rozmiar',
        type: 'text',
        disabled: true,
    },
    note: {
        label: 'Notatka',
        type: 'text',
        size: 8,
    },
    editedByAdmin: {
        label: 'Edytowano przez Admina / dane zaakceptowane',
        type: 'switch',
    },
    type: {
        label: 'Typ',
        type: 'text',
    },
    slug: {
        label: 'Slug (unikalna nazwa w URL)',
        type: 'text',
        size: 12,
    },
    isActive: {
        label: 'Aktywny',
        type: 'switch',
    },
    isRejected: {
        label: 'Odrzucony',
        type: 'switch',
    },
    firstName: {
        label: 'Imię',
        type: 'text',
    },
    lastName: {
        label: 'Nazwisko',
        type: 'text',
    },
    street: {
        label: 'Ulica i numer',
        type: 'text',
    },
    zip: {
        label: 'Kod pocztowy',
        type: 'text',
    },
    cityPost: {
        label: 'Miejscowość (poczta)',
        type: 'text',
    },
    city: {
        label: 'Miejscowość (bez edycji)',
        type: 'text',
        // hidden: true,
    },
    cityId: {
        label: 'Miejscowość (wyszukiwarka)',
        type: 'cityInput',
    },
    area: {
        label: 'Województwo (bez edycji)',
        type: 'text',
    },
    fullAddress: {
        label: 'Pełny adres',
        type: 'text',
    },
    email: {
        label: 'Adres e-mail',
        type: 'text',
    },
    www: {
        label: 'Adres strony internetowej (na początku http:// lub https://)',
        type: 'text',
    },
    phone: {
        label: 'Numer telefonu',
        type: 'text',
    },
    phone2: {
        label: 'Numer telefonu 2',
        type: 'text',
    },
    locationLng: {
        label: 'Długość geograficzna',
        type: 'text',
    },
    locationLat: {
        label: 'Szerokość geograficzna',
        type: 'text',
    },
    location: {
        label: 'Lokalizacja (punkt)',
        type: 'point',
        hidden: true,
    },
    pageViews: {
        label: 'Ilość odsłon strony',
        type: 'text',
        hidden: true,
    },
    password: {
        label: 'Hasło',
        type: 'text',
        disabled: true,
        hidden: true,
    },
    picture: {
        label: 'Zdjęcie profilowe',
        type: 'text',
        disabled: true,
        hidden: true,
    },
    phoneViews: {
        label: 'Ilość odsłon telefonu',
        type: 'text',
        hidden: true,
    },
    userType: {
        label: 'Typ użytkownika (10 - user, 20 - lek. wet., 100 - admin)',
        type: 'text',
    },
    searchIndex: {
        label: 'Indeks wyszukiwania (bez edycji)',
        type: 'text',
        hidden: true,
    },
    verificationDate: {
        label: 'Data weryfikacji',
        type: 'text',
        hidden: true,
    },
    userId: {
        label: 'Użytkownik',
        type: 'objectId',
        model: 'user',
        // hidden: true,
    },
    serviceId: {
        label: 'Usługa (nadrzędna)',
        type: 'objectId',
        model: 'service',
    },
    lastEditedBy: {
        label: 'Ostatnio edytowany przez',
        type: 'text',
        hidden: true,
    },
    createdAt: {
        label: 'Data utworzenia',
        type: 'text',
        disabled: true,
    },
    updatedAt: {
        label: 'Data modyfikacji',
        type: 'text',
        disabled: true,
    },
    content: {
        label: 'Treść',
        type: 'textarea',
    },
    improvement: {
        label: 'Usprawnienia',
        type: 'textarea',
    },
    service: {
        label: 'Usługa',
        type: 'text',
    },
    authorFirstName: {
        label: 'Autor imię (bez rejestracji)',
        type: 'text',
    },
    authorEmail: {
        label: 'Autor e-mail (bez rejestracji)',
        type: 'text',
    },
    rate: {
        label: 'Ocena',
        type: 'text',
    },
    vetId: {
        label: 'Placówka (ID)',
        type: 'text',
        // disabled: true,
    },
    registerIp: {
        label: 'Adres IP (dodania wpisu)',
        type: 'text',
    },
    comment: {
        label: 'Komentarz / notatka',
        type: 'text',
    },
    pwzNumber: {
        label: 'Numer PWZ',
        type: 'text',
    },
    title: {
        label: 'Tytuł zawodowy / naukowy',
        type: 'text',
    },
    verifiedByAdmin: {
        label: 'Zweryfikowane przez admina',
        type: 'switch',
    },
    answersCount: {
        label: 'Ilość odpowiedzi',
        type: 'text',
        disabled: true,
    },
    parentId: {
        label: 'ID pytania nadrzędnego',
        type: 'text',
        disabled: true,
    },
    fbProfileId: {
        label: 'ID profilu Facebook',
        type: 'text',
        disabled: true,
    },
    path: {
        label: 'Ścieżka do pliku',
        type: 'text',
        disabled: true,
    },
    typeLabel: {
        label: 'Typ (opis) - do podglądu',
        type: 'text',
        disabled: true,
    },
    description: {
        label: 'Opis',
        type: 'text',
    },
    isVeterinarian: {
        label: 'Lekarz weterynarii',
        type: 'switch',
    },
    isClinicOwner: {
        label: 'Właściciel placówki',
        type: 'switch',
    },
    isBlocked: {
        label: 'Zablokowany',
        type: 'switch',
    },
    isVerified: {
        label: 'Zweryfikowany',
        type: 'switch',
    },
    isPublic: {
        label: 'Profil publiczny',
        type: 'switch',
    },
    companyName: { label: 'companyName', type: 'text' },
    companyStreet: { label: 'companyStreet', type: 'text' },
    companyZip: { label: 'companyZip', type: 'text' },
    companyCity: { label: 'companyCity', type: 'text' },
    companyFullAddress: { label: 'companyFullAddress', type: 'text' },
    province: { label: 'province', type: 'text' },
    contact: { label: 'contact', type: 'text' },

};
