import axios, { AxiosRequestConfig } from 'axios';
import envConfig from '../config/config';

const http = (admin = false) => {
    const config: AxiosRequestConfig = {
        baseURL: `${envConfig.apiUrl}${admin ? '/admin-panel-wp-2021' : ''}`,
        timeout: 120000,
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('wp-token') || '',
        },
    };
    return axios.create(config);
}


export default http;

