import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import ReviewsIcon from '@mui/icons-material/Reviews';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

export const MainListItems = () => {
    const navigate = useNavigate();

    const goTo = (url: string) => {
        navigate(url);
    }

    return (
        <div>
            <ListItem button onClick={() => goTo('/vets')}>
                <ListItemIcon>
                    <OtherHousesIcon />
                </ListItemIcon>
                <ListItemText primary="Placówki" />
            </ListItem>

            <ListItem button onClick={() => goTo('/vets-comp')}>
                <ListItemIcon>
                    <OtherHousesIcon />
                </ListItemIcon>
                <ListItemText primary="Placówki NEW" />
            </ListItem>

            <ListItem button onClick={() => goTo('/reviews')}>
                <ListItemIcon>
                    <ReviewsIcon />
                </ListItemIcon>
                <ListItemText primary="Opinie" />
            </ListItem>
            <ListItem button onClick={() => goTo('/questions')}>
                <ListItemIcon>
                    <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText primary="Pytania do lek. wet." />
            </ListItem>
            <ListItem button onClick={() => goTo('/files')}>
                <ListItemIcon>
                    <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary="Pliki" />
            </ListItem>

            <ListItem button onClick={() => goTo('/services')}>
                <ListItemIcon>
                    <MiscellaneousServicesIcon />
                </ListItemIcon>
                <ListItemText primary="Usługi" />
            </ListItem>

            <ListItem button onClick={() => goTo('/vet-owners')}>
                <ListItemIcon>
                    <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Przypisanie W-U" />
            </ListItem>

            <ListItem button onClick={() => goTo('/users')}>
                <ListItemIcon>
                    <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Użytkownicy" />
            </ListItem>
            <ListItem button onClick={() => goTo('/logins')}>
                <ListItemIcon>
                    <LockIcon />
                </ListItemIcon>
                <ListItemText primary="Zapis logowania" />
            </ListItem>



            {/*<ListItem button>*/}
            {/*    <ListItemIcon>*/}
            {/*        <LayersIcon />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Integrations" />*/}
            {/*</ListItem>*/}
        </div>
    );
};

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
