import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import CrudList from '../components/CrudList/CrudList';

const VetOwnersIndex = () => {
    const headers = [
        { key: 'vetName', label: 'Placówka', className: '' },
        { key: 'userName', label: 'Użytkownik', className: '' },
        { key: 'actions', label: 'Akcje', className: 'small', actions: ['delete'] },
    ];

    return (
        <Dashboard>
            <CrudList
                pageHeader="Prośby o przypisanie użytkownikowi placówki do edycji"
                headers={headers}
                apiIndex="/vet-owners"
                apiUpdate="/vet-owners"
                apiDelete="/vet-owners"
                pageSize={25}
            />
        </Dashboard>
    );
};
export default VetOwnersIndex;
