import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import { Grid } from '@mui/material';

const Home = () => {
    return (
        <Dashboard>
            <Grid container spacing={2}>
                <Grid item sx={{ padding: '20px' }}>
                    HOME
                </Grid>
            </Grid>
        </Dashboard>
    );
};
export default Home;