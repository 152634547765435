import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import Dashboard from '../components/Layout/Dashboard';

const NotFound = () => {
    return (
        <Dashboard>
            <NotFoundContent>
                Błąd 404
                <br />
                Nie znaleziono strony
                <br />
                <Link to="/"><KeyboardBackspaceIcon /> <span>Wróć na stronę główną</span></Link>
            </NotFoundContent>
        </Dashboard>
    );
};
export default NotFound;

//styles
const NotFoundContent = styled(Box)({
    textAlign: 'center',
    padding: '40px 0',
    fontSize: 24,
    fontWeight: 'bold',
});
