import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IUser from '../types/IUser';
import { AppBar, Container, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { logout } from '../store/actions';

const WhiteLink = styled(Link)({
    color: '#fff',
});

const MenuItems = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    '.username': {
        paddingLeft: 50,
    },
    a: {
        marginRight: 15,
    }
});

const Layout = ({ children }: { children: any }) => {
    const user = useSelector((state: any) => state.user);
    const isAuth = useSelector((state: any) => state.isAuth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loggedUser, setLoggedUser] = useState<IUser | null>(null);

    useEffect(() => {
        setLoggedUser(user);
    }, [user]);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    return (
        <>
            {isAuth && <AppBar position="static" sx={{ mb: 2 }}>
                <Container maxWidth="xl">
                    <MenuItems>
                        <WhiteLink to={'/vets'}>Weterynarze</WhiteLink>
                        <WhiteLink to={'/reviews'}>Opinie</WhiteLink>
                        {loggedUser && <div className="username">Witaj {loggedUser.firstName} {loggedUser.lastName}</div>}
                        <Button onClick={handleLogout} sx={{ ml: '10px', color: '#fff' }} variant={'outlined'}>Wyloguj się</Button>
                    </MenuItems>
                </Container>
            </AppBar>}
            <Container maxWidth="xl">
                {children}
            </Container>
        </>
    );
};
export default Layout;