import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import CrudList from '../components/CrudList/CrudList';

const ServicesIndex = () => {
    const headers = [
        { key: 'id', label: 'ID', className: 'small' },
        { key: 'name', label: 'Nazwa', className: '' },
        { key: 'serviceId', label: 'Child Service', className: '', component: 'boolean' },
        { key: 'actions', label: 'Akcje', className: 'small', actions: ['edit'] },
    ];

    const initial = {
        name: '',
        slug: '',
        description: '',
        serviceId: null,
    };

    return (
        <Dashboard>
            <CrudList
                pageHeader="Usługi"
                objectInitial={initial}
                headers={headers}
                apiIndex="/services"
                apiUpdate="/services"
                apiDelete="/services"
                pageSize={25}
            />
        </Dashboard>
    );
};
export default ServicesIndex;
