import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './store/actions';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import ScrollToTop from './components/helpers/ScrollToTop';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import VetsIndex from './pages/VetsIndex';
import ReviewsIndex from './pages/ReviewsIndex';
import QuestionsIndex from './pages/QuestionsIndex';
import FilesIndex from './pages/FilesIndex';
import UsersIndex from './pages/UsersIndex';
import LoginsIndex from './pages/LoginsIndex';
import VetOwnersIndex from './pages/VetOwnersIndex';
import ServicesIndex from './pages/ServicesIndex';
import VetsCompIndex from './pages/VetsCompIndex';

function App() {
  return (
    <AuthProvider>
      <>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/">
              <Route index element={<RequireAuth><Home /></RequireAuth>} />
              <Route path="login" element={<LoginPage />} />

              <Route path="vets">
                <Route index element={<RequireAuth><VetsIndex /></RequireAuth>} />
              </Route>

              <Route path="vets-comp">
                <Route index element={<RequireAuth><VetsCompIndex /></RequireAuth>} />
              </Route>

              <Route path="reviews">
                <Route index element={<RequireAuth><ReviewsIndex /></RequireAuth>} />
              </Route>

              <Route path="questions">
                <Route index element={<RequireAuth><QuestionsIndex /></RequireAuth>} />
              </Route>

              <Route path="files">
                <Route index element={<RequireAuth><FilesIndex /></RequireAuth>} />
              </Route>

              <Route path="users">
                <Route index element={<RequireAuth><UsersIndex /></RequireAuth>} />
              </Route>

              <Route path="logins">
                <Route index element={<RequireAuth><LoginsIndex /></RequireAuth>} />
              </Route>

              <Route path="services">
                <Route index element={<RequireAuth><ServicesIndex /></RequireAuth>} />
              </Route>

              <Route path="vet-owners">
                <Route index element={<RequireAuth><VetOwnersIndex /></RequireAuth>} />
              </Route>

              <Route path="404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    </AuthProvider>
  );
}
export default App;

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const currentCalled = useSelector((state: any) => state.currentCalled);
  useEffect(() => {
    const token = localStorage.getItem('wp-token') || '';
    dispatch(login(token));
  }, [dispatch]);
  return currentCalled ? children : <></>;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuth = useSelector((state: any) => state.isAuth);
  const location = useLocation();
  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};
