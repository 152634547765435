import React, { useCallback, useEffect, useRef, useState } from 'react';
import http from '../Api/http';
import Layout from '../components/Layout';
import {
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TablePagination, TextField, Button, Modal, Grid, Switch, InputLabel, Tooltip, Box
} from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useSelector } from 'react-redux';
import CityInput from '../components/CityInput/CityInput';
import { fieldsConfig, vetTypes } from '../config/fields';
import { BeatLoader } from 'react-spinners';
import slugify from 'slugify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dashboard from '../components/Layout/Dashboard';
import ObjectInput from '../components/ObjectInput/ObjectInput';

export const getSlug = (text: string = ''): string => {
    return slugify(text, {
        replacement: '-',
        locale: 'pl',
        lower: true,
        trim: true,
        remove: /[*+~.,()'"!:@]/g
    });
};

export const TableHeadStyled = styled(TableHead)({
    th: {
        fontWeight: 'bold',
        minWidth: 150,
        '&.small': {
            width: 30,
            minWidth: 'auto',
        },
    },
});

const CardStyled = styled(Card)({
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    overflowY: 'auto',
});

const SearchWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    '.MuiTextField-root': {
        marginTop: 0,
        marginBottom: 0,
        input: {
            width: 500,
        },
    },
    button: {
        marginLeft: 20,
    },
});

const VetsCompIndex = () => {
    const [vets, setVets] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [query, setQuery] = useState('');
    const [triggerQuery, setTriggerQuery] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [vetDetails, setVetDetails] = useState<any>(null);
    const [errMessage, setErrMessage] = useState<string>('');
    const [notUpdateFields] = useState<any[]>(['id', 'area', 'fullAddress', 'location', 'createdAt', 'updatedAt', 'phoneViews', 'pageViews', 'searchIndex']);
    const queryRef = useRef();
    const isAuth = useSelector((state: any) => state.isAuth);

    const [comparing, setComparing] = useState<any>(false);
    const [vetsToCompare, setVetsToCompare] = useState<any>([]);
    const [vetsSimilar, setVetsSimilar] = useState<any>([]);
    const queryInput = useRef();
    const [itemsCounts, setItemsCounts] = useState<any>({
        activeVetsCount: 0,
        editedVetsCount: 0,
        rejectedVetsCount: 0,
    });
    const [filters, setFilters] = useState<any>({
        active: false,
        edited: false,
        rejected: false,
        all: true,
    });

    const filterFields = [
        'edited',
        'active',
        'rejected',
        'all',
    ];
    const filterFieldsLabels: any = {
        active: 'Aktywni',
        edited: 'Edytowani',
        rejected: 'Odrzuceni',
        all: 'Wszyscy',
    };

    const [currentVetId, setCurrentVetId] = useState(null);

    useEffect(() => {
        if (isAuth) {
            getVets(currentPage);
        }
    }, [currentPage, perPage, triggerQuery, isAuth, filters]);

    useEffect(() => {
        setCurrentPage(0);
    }, [filters]);

    useEffect(() => {
        if (vetDetails && vetDetails.id && vetDetails.id !== currentVetId) {
            setCurrentVetId(vetDetails.id);
            setTimeout(() => selectCity(), 100);
        }
    }, [vetDetails]);

    const getVets = (page = 0) => {

        let filtersArray: any[] = [];
        filterFields.forEach((f) => {
            if (filters[f]) {
                filtersArray.push(`${f}=true`);
            }
        });

        http(true).get(`/vets-comp?page=${page}&per_page=${perPage}&query=${query}${filtersArray.length ? `&${filtersArray.join('&')}` : ''}`)
            .then(({ data }) => {
                setVets(data.content);
                setTotal(data.total);
            });

        http(true).get(`/vets-comp/counts`)
            .then(({ data }) => {
                setItemsCounts(data);
            });
    };

    const updateVetDetails = (field: string, ev: any, isBool = false) => {
        const updatedVetDetails = { ...vetDetails };
        updatedVetDetails[field] = isBool ? ev.target.checked : ev.target.value;
        if (field === 'name') {
            updatedVetDetails.slug = getSlug(updatedVetDetails.name);
        }
        setVetDetails(updatedVetDetails);
    };

    const updateVet = () => {
        setSaving(true);
        setErrMessage('');
        const objectId = vetDetails.id;
        const objectToSave = { ...vetDetails };
        notUpdateFields.forEach((field) => {
            delete objectToSave[field];
        });
        http(true).patch(`/vets-comp/${objectId}`, { ...objectToSave })
            .then(({ data }) => {
                if (data && data.status && data.status === 'ok') {
                    setIsModalOpen(false);
                    if (Array.isArray(vets)) {
                        setVets(vets.map((vet: any) => {
                            const vetUp = {...vet};
                            if (vetUp.id === vetDetails.id) {
                                Object.assign(vetUp, {...vetDetails});
                            }
                            return vetUp;
                        }));
                    }
                } else if (data && data.status && data.status === 'err') {
                    setErrMessage(`${data.message} [${data.records}]`);
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const createVet = () => {
        setSaving(true);
        setErrMessage('');

        const t = vetTypes.find((item) => item.name === vetDetails.type);
        let type = 0;
        if (t && t.id) {
            type = t.id;
        }

        const vetToCreate = {
            name: vetDetails.name,
            slug: vetDetails.slug,
            street: vetDetails.street,
            zip: vetDetails.zip,
            cityId: vetDetails.cityId,
            phone: vetDetails.phone,
            phone2: vetDetails.phone2,
            www: vetDetails.www,
            email: vetDetails.email,
            fullAddress: '',
            type,
            matchedId: vetDetails.id,
            firstName: vetDetails.companyName,
        };

        // console.log(vetToCreate);
        // return false;

        http(true).post('/vets', vetToCreate)
            .then(({ data }) => {
                if (data && data.status && data.status === 'ok') {
                    setIsModalOpen(false);
                    http(true).patch(`/vets-comp/activate/${vetDetails.id}`, { isActive: true, editedByAdmin: true });

                    setVets(vets.map((vet: any) => {
                        const vetUp = {...vet};
                        if (vetUp.id === vetDetails.id) {
                            Object.assign(vetUp, {...vetUp, isActive: true, editedByAdmin: true });
                        }
                        return vetUp;
                    }));

                } else if (data && data.status && data.status === 'err') {
                    setErrMessage(`${data.message} [${data.records}]`);
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };


    const matchVet = (vet: any) => {
        setSaving(true);
        http(true).patch(`/vets-comp/compare/${vet.id}`, { matchedId: vetDetails.id })
            .then(({ data }) => {
                if (data && data.status && data.status === 'ok') {
                    if (Array.isArray(vetsToCompare)) {
                        setVetsToCompare(vetsToCompare.map((v: any) => {
                            const vetUp = {...v};
                            if (vetUp.id === vet.id) {
                                Object.assign(vetUp, {...vetUp, matchedId: vetDetails.id});
                            }
                            return vetUp;
                        }));
                    }
                }
            })
            .finally(() => {
                setSaving(false);
            });
    }



    const handlePerPage = useCallback((event: any) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    }, []);

    const handleSearch = (ev?: any) => {
        if ((!ev || (ev && ev.code === 'Enter')) && queryRef && queryRef.current) {
            setQuery((queryRef.current as { value: string }).value);
            setCurrentPage(0);
            setTriggerQuery(triggerQuery + 1);
        }
    };

    const openVetDetails = (vet: any) => {
        vet.cityId = null;
        vet.phone2 = null;
        vet.street = `ul. ${vet.street.replaceAll('ul. ', '')}`;
        vet.name = vet.name.trim();
        vet.slug = getSlug(vet.name);
        vet.phone = transformPhone(vet.contact);

        setErrMessage('');
        setVetDetails({ ...vet });
        setVetsToCompare([]);
        setVetsSimilar([]);
        // loadVetsToCompare(vet);
        setIsModalOpen(true);
    };

    const transformPhone = (contact: string) => {
        if (contact) {
            const newContacts =
              contact
                .trim()
                .replaceAll(' ', '')
                .replaceAll('-', '')
                .replaceAll('/', '')
                .replaceAll('+48', '')
                .replace('Telefon:', '')
                .split(',');

            const p = 0;
            // for (let p = 0; p < newContacts.length; p++) {
            let toRet = '+48 ';
            if (newContacts[p].length >= 9) {
                for (let i = 0; i < newContacts[p].length; i++) {
                    toRet = `${toRet}${newContacts[p][i]}`;
                    if ((i+1) % 3 === 0) {
                        toRet = `${toRet} `;
                    }
                }
            }
            return toRet;
            // }
        }
        return '';
    };

    const loadVetsToCompare = (vet: any, q: string = '') => {
        setComparing(true);
        if (!q) {
            q = vet.fullAddress;
        }
        http(true).get(`/vets-comp/compare/${vet.id}?query=${q}`)
            .then(({ data }) => {
                if (data && data.vets) {
                    setVetsToCompare(data.vets);
                }
                if (data && data.vetsSimilar) {
                    setVetsSimilar(data.vetsSimilar);
                }
            })
            .finally(() => setComparing(false));
    }

    const reloadVetsToCompare = (vet: any) => {
        if (queryInput && queryInput.current) {
            loadVetsToCompare(vet, (queryInput.current as { value: string }).value);
        }
    };

    const isVisible = (key: string) => {
        return !(fieldsConfig && fieldsConfig[key] && fieldsConfig[key].hidden);
    };

    const getLabel = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].label) ? fieldsConfig[key].label : key;
    };

    const getFieldModel = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].model) ? fieldsConfig[key].model : key;
    };

    const getFieldType = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].type) ? fieldsConfig[key].type : key;
    };

    const getSize = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].size) ? fieldsConfig[key].size : 4;
    };

    const updateFilter = (filter: string) => {
        const modifiedFilters = { ...filters };
        if (filter === 'all' && !filters[filter]) {
            filterFields.forEach((k) => {
                modifiedFilters[k] = false;
            });
        } else if (filter !== 'all' && filters.all && !filters[filter]) {
            modifiedFilters.all = false;
        }
        modifiedFilters[filter] = !filters[filter];
        setFilters(modifiedFilters);
    };

    const selectCity = () => {
        setSaving(true);
        http(true).get(`/cities/?query=${vetDetails.city}&create=true&province=${vetDetails.province}`)
          .then(({ data }) => {
              updateVetDetails('cityId', { target: { value: data.content[0].id }});
          })
          .finally(() => setSaving(false));
    }

    return (
        <Dashboard>
            <Card>
                <CardHeader title="Gabinety weterynaryjne"></CardHeader>
                <CardContent>
                    <SearchWrapper>
                        <TextField
                            margin="normal"
                            label="Wyszukaj gabinet"
                            name="query"
                            type="text"
                            size="small"
                            inputRef={queryRef}
                            onKeyDown={handleSearch}
                        />
                        <Button onClick={() => handleSearch()} variant={'contained'} startIcon={<SearchIcon />}>Szukaj</Button>
                        <CountsWrapper>
                            <div>Edytowano: <strong>{itemsCounts.editedVetsCount}</strong></div>
                            <div>Aktywnych: <strong>{itemsCounts.activeVetsCount}</strong></div>
                            <div>Odrzuconych: <strong>{itemsCounts.rejectedVetsCount}</strong></div>
                        </CountsWrapper>
                    </SearchWrapper>

                    <FiltersWrapper>
                        <SwitchWrapper>
                            {filterFields.length > 0 && filterFields.map((field: string, key: number) => {
                                return (
                                    <div key={key}>
                                        <Switch
                                            onChange={(ev) => updateFilter(field)}
                                            checked={filters[field]}
                                        />
                                        <InputLabel>{filterFieldsLabels[field]}</InputLabel>
                                    </div>
                                )
                            })}
                        </SwitchWrapper>
                    </FiltersWrapper>

                    <Table size="small">
                        <TableHeadStyled>
                            <TableRow>
                                <TableCell className="small">ID</TableCell>
                                <TableCell>Nazwa</TableCell>
                                <TableCell>Miejscowość</TableCell>
                                <TableCell>Kontakt</TableCell>
                                <TableCell>MatchedID</TableCell>
                                <TableCell className="small">Admin</TableCell>
                                <TableCell className="small">Aktywny</TableCell>
                                <TableCell className="small">Akcje</TableCell>
                            </TableRow>
                        </TableHeadStyled>
                        <TableBody>
                            {vets.length > 0 && vets.map((vet: any, index) => (<TableRow key={index}>
                                <TableCell>{vet.id}</TableCell>
                                <TableCell>{vet.name}</TableCell>
                                <TableCell>{vet.city}</TableCell>
                                <TableCell>{vet.contact}</TableCell>
                                <TableCell>{vet.matchedId}</TableCell>
                                <TableCell align={'center'}>
                                    {vet.editedByAdmin ? <CheckIcon sx={{ color: 'green' }} fontSize="large" /> : '---'}
                                </TableCell>
                                <TableCell align={'center'}>
                                    {vet.isActive ? <CheckIcon sx={{ color: 'green' }} fontSize="large" /> : '---'}
                                </TableCell>
                                <TableCell align={'center'}><Button onClick={() => openVetDetails(vet)}><EditIcon /></Button></TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component={'div'}
                        count={total}
                        page={currentPage}
                        onPageChange={(ev, nextPage) => setCurrentPage(nextPage)}
                        rowsPerPage={perPage}
                        onRowsPerPageChange={handlePerPage}
                    />
                </CardContent>
            </Card>

            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <CardStyled>
                    <CardHeader title={`Edycja gabinetu (ID: ${vetDetails && vetDetails.id})`} />
                    <CloseLink><ClearIcon fontSize={'large'} onClick={() => setIsModalOpen(false)} /></CloseLink>
                    <CardContent>
                        <Grid container spacing={2}>
                        {vetDetails && Object.keys(vetDetails)
                            .filter((k) => isVisible(k))
                            .map((k, index) => {
                                return (
                                    <Grid item md={getSize(k)} key={index}>
                                        {getFieldType(k) === 'objectId' && <ObjectInput
                                            onChange={(objId) => updateVetDetails(k, { target: { value: objId }})}
                                            label={getLabel(k)}
                                            model={getFieldModel(k)}
                                            objectId={vetDetails[k]} />}
                                        {getFieldType(k) === 'switch' && <SwitchWrapper><Switch
                                            onChange={(ev) => updateVetDetails(k, ev, true)}
                                            checked={!!vetDetails[k]} /> <InputLabel>{getLabel(k)}</InputLabel></SwitchWrapper>}
                                        {getFieldType(k) === 'cityInput' && <CityInput
                                            onChange={(cityId) => updateVetDetails(k, { target: { value: cityId }})}
                                            label={getLabel(k)}
                                            cityId={vetDetails[k]} />}
                                        {getFieldType(k) === 'text' && <TextField
                                            label={getLabel(k)}
                                            onChange={(ev) => updateVetDetails(k, ev)}
                                            disabled={notUpdateFields.includes(k)}
                                            fullWidth size={'small'}
                                            value={vetDetails[k] || ''} />}
                                        {getFieldType(k) === 'point' && <InputLabel>{getLabel(k)}: {vetDetails[k].coordinates.join(', ')}</InputLabel>}
                                   </Grid>
                                )
                            })}

                            <Grid item md={12}>
                                <Button variant={'contained'} onClick={() => updateVet()}>Zapisz zmiany</Button>
                                <Button variant={'contained'} sx={{ ml: '20px' }} onClick={() => createVet()}>Utwórz zweryfikowany wpis placówki</Button>
                                <Button variant={'contained'} sx={{ ml: '20px' }} onClick={() => selectCity()}>Przepisz miasto</Button>
                                <Button variant={'outlined'} sx={{ ml: '20px' }} onClick={() => setIsModalOpen(false)}>Zamknij</Button>
                                {saving && <span style={{ marginLeft: '20px' }}>Zapisuję zmiany...</span>}
                            </Grid>
                            {errMessage && <Grid item md={12} sx={{ color: 'red', fontWeight: 'bold' }}>
                                {errMessage}
                            </Grid>}

                            <Grid item md={12}>
                                <Grid container>
                                    <Grid item md={6} sx={{ padding: '10px' }}>
                                        {/*<h3>Gabinety w bazie weteo.pl</h3>*/}
                                        {/*{comparing && <BeatLoader />}*/}
                                        {/*{!comparing && vetsSimilar.length > 0 && vetsSimilar.map((vet: any, key: number) => {*/}
                                        {/*    return (*/}
                                        {/*        <div style={{ padding: '0 0 10px', marginBottom: '10px', borderBottom: 'solid 1px grey'}} key={key}>*/}
                                        {/*            <VetName>*/}
                                        {/*                [{vet.id}]*/}
                                        {/*                {vet.editedByAdmin ? <VerifiedUserIcon sx={{ color: 'green' }} fontSize="small" /> : <GppMaybeIcon sx={{ color: 'orange' }} fontSize="small" />}*/}
                                        {/*                {vet.isActive ? <CheckIcon sx={{ color: 'green' }} fontSize="small" /> : <ClearIcon sx={{ color: 'red' }} fontSize="small" />}*/}
                                        {/*                {vet.isActive} {vet.name}*/}
                                        {/*            </VetName>*/}
                                        {/*            {vet.fullAddress}*/}
                                        {/*        </div>*/}
                                        {/*    )*/}
                                        {/*})}*/}

                                        {vetDetails && vetDetails.name && <a href={`https://google.com/search?q=${vetDetails.name + ' ' + vetDetails.city}`} target="_blank">Sprawdź w google</a>}


                                    </Grid>
                                    <Grid item md={6} sx={{ padding: '10px' }}>
                                        <h3>WetSystems</h3>
                                        <TextField size="small" defaultValue={vetDetails && vetDetails.street} inputRef={queryInput} />
                                        <Button variant="contained" onClick={() => reloadVetsToCompare(vetDetails)}>Odśwież</Button>
                                        <hr />
                                        {comparing && <BeatLoader />}
                                        {!comparing && vetsToCompare.length > 0 && vetsToCompare.map((vet: any, key: number) => {
                                            return (
                                                <div style={{ padding: '0 0 10px', marginBottom: '10px', borderBottom: 'solid 1px grey'}} key={key}>
                                                    <MatchedItem>
                                                        Typ: <strong>{vet.type}</strong>
                                                        {vet.matchedId && vet.matchedId === vetDetails.id && <Tooltip title={'Gabinet połączony z edytowanym obiektem'}>
                                                            <CheckCircleIcon style={{ color: 'blue' }} /></Tooltip>}
                                                        {(!vet.matchedId || vet.matchedId !== vetDetails.id) &&
                                                        <Button size="small" onClick={() => matchVet(vet)} variant="contained">Połącz</Button>}
                                                        {vet.matchedId > 0 && <span>Już połączony z <strong>[{vet.matchedId}]</strong></span>}
                                                    </MatchedItem>
                                                    {vet.name}<br />{vet.fullAddress}<br />{vet.contact}<br />
                                                    <hr style={{ height: 0, border: 'none', borderBottom: 'dashed 1px #ccc', lineHeight: 0 }} />
                                                    <strong>Podmiot prowadzący:</strong><br />
                                                    {vet.companyName}<br />{vet.companyFullAddress}<br />
                                                </div>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardStyled>
            </Modal>
        </Dashboard>
    );
};
export default VetsCompIndex;


// styles
const SwitchWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const CloseLink = styled('div')({
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
});

const VetName = styled('div')({
    alignItems: 'center',
    display: 'flex',
});

const MatchedItem = styled('div')({
    alignItems: 'center',
    display: 'flex',
    strong: {
        marginRight: '15px',
    },
});

const FiltersWrapper = styled('div')({
    alignItems: 'center',
    display: 'flex',
    div: {
        alignItems: 'center',
        display: 'flex',
        marginRight: '20px',
    },
});

const CountsWrapper = styled('div')({
    alignItems: 'center',
    display: 'flex',
    marginLeft: '30px',
    div: {
        padding: '5px 10px',
    },
});
