import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import http from '../../Api/http';
import { BeatLoader } from 'react-spinners';

const ObjectInput = (
    { label, objectId, model = 'city', onChange }:
        { label: string; objectId?: number | null, model: string, onChange: (objectId: number) => void }
) => {
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [preselectedValue, setPreselectedValue] = useState<any>(null);
    const [isApiLoading, setIsApiLoading] = useState<boolean>(false);

    useEffect(() => {
        if (objectId) {
            setIsApiLoading(true);
            onSearch('', objectId);
        }
    }, [objectId]);

    const onSearch = (query: string = '', id?: number) => {
        if (query.length >= 3 || id) {
            http(true).get(getSearchApiURL(query, id))
                .then(({data}) => {
                    setSearchResults((data && data.content) || []);
                    if (id && data && data.content && data.content[0]) {
                        setPreselectedValue(data.content[0]);
                    }
                })
                .finally(() => setIsApiLoading(false));
        }
    };

    const getSearchApiURL = (query: string = '', id?: number) => {
        if (model === 'city') {
            return `/cities?query=${query}${id ? `&id=${id}` : ''}`;
        } else if (model === 'user') {
            return `/users?query=${query}${id ? `&id=${id}` : ''}`
        } else if (model === 'service') {
            return `/services?query=${query}${id ? `&id=${id}` : ''}`
        }
        return '/';
    };

    const onSearchSelect = (ev: any, value: any) => {
        if (value && value.id) {
            onChange(value.id);
        }
    };

    const getOptionLabel = (option: any) => {
        if (model === 'city' || model === 'service') {
            return option.name;
        } else if (model === 'user') {
            return `${option.firstName} ${option.lastName} [${option.id}]`;
        }
        return 'Błąd! Brak dopasowania!';
    };

    return (
        <>
            {isApiLoading && <BeatLoader />}
            {!isApiLoading && <Autocomplete
                freeSolo
                // clearOnEscape={false}
                // clearOnBlur={false}
                value={preselectedValue}
                onInputChange={(event, newValue) => {
                    onSearch(newValue);
                }}
                onChange={(ev, values) => onSearchSelect(ev, values)}
                options={searchResults}
                filterOptions={(options) => options}
                getOptionLabel={(option: any) => getOptionLabel(option)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        size={'small'}
                        label={label}
                    />
                }
                renderOption={(props: any, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {model === 'city' && <span>[{option.id}] {option.name} ({option.provinceName})</span>}
                            {model === 'service' && <span>[{option.id}] {option.name}</span>}
                            {model === 'user' && <span>[{option.id}] {option.firstName} {option.lastName} ({option.email})</span>}
                        </li>
                    );
                }}
            />}
        </>
    )
};
export default ObjectInput;
