import React from 'react';
import { Box, Button, Dialog, DialogContent } from '@mui/material';

const QuestionDialog = (
    { question, opened = false, onConfirm, onClose }:
        { question: string, opened: boolean, onConfirm: () => void, onClose: () => void }
) => {
    return (
        <Dialog open={opened}>
            <DialogContent>
                <Box sx={{ mb: '20px' }}>{question}</Box>
                <Box>
                    <Button variant="contained" onClick={onConfirm} sx={{ mr: '20px' }}>Usuń</Button>
                    <Button variant="outlined" onClick={onClose}>Anuluj</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
export default QuestionDialog;