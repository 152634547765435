import React, { useCallback, useEffect, useState } from 'react';
import http from '../../Api/http';
import {
    Card,
    CardContent,
    CardHeader,
    TablePagination,
    TextField,
    Button,
    Modal,
    Grid,
    Switch,
    InputLabel,
    Table,
    TableRow, TableCell, TableBody, Box, Rating,
} from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { fieldsConfig } from '../../config/fields';
import { useSearchParams } from 'react-router-dom';
import { TableHeadStyled } from '../../pages/VetsIndex';
import QuestionDialog from '../helpers/QuestionDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import config from '../../config/config';
import ObjectInput from '../ObjectInput/ObjectInput';


const CrudList = (
    { pageHeader, objectInitial = null, apiIndex, apiUpdate, apiDelete, headers, pageSize = 10}:
        { pageHeader: string, objectInitial?: any, apiIndex: string, apiUpdate?: string, apiDelete?: string, headers: any[], pageSize?: number }
) => {
    const [records, setRecords] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(pageSize);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState<any>(null);
    const [saving, setSaving] = useState(false);
    const [recordDetails, setRecordDetails] = useState<any>({});
    const [errMessage, setErrMessage] = useState<string>('');
    const [notUpdateFields, setNotUpdateFields] = useState<string[]>([]);
    const isAuth = useSelector((state: any) => state.isAuth);
    const [urlQuery] = useSearchParams();

    const isActive = urlQuery.get('is_active') || '';

    useEffect(() => {
        if (isAuth) {
            getRecords(currentPage, isActive);

            const disabledFields: string[] = [];
            Object.keys(fieldsConfig).forEach((key: string) => {
                if (fieldsConfig[key].hidden || fieldsConfig[key].disabled) {
                    disabledFields.push(key);
                }
            });
            setNotUpdateFields(disabledFields);
        }
    }, [currentPage, perPage, isAuth, urlQuery]);

    const getRecords = (page = 0, isActive: any = '') => {
        http(true).get(`${apiIndex}?page=${page}&per_page=${perPage}${isActive !== '' ? `&is_active=${isActive}` : ''}`)
            .then(({ data }) => {
                setRecords(data.content);
                setTotal(data.total);
            });
    };

    const openDeleteDialog = (record: any) => {
        setRecordToDelete(record);
        setIsDeleteModalOpened(true);
    };

    const closeDeleteDialog = () => {
        setRecordToDelete(null);
        setIsDeleteModalOpened(false);
    };

    const deleteRecord = () => {
        http(true).delete(`${apiDelete}/${recordToDelete.id}`)
            .then(() => {
                getRecords(currentPage, isActive);
            })
            .catch(() => {
                alert('Nie można usunąć wpisu!');
            })
            .finally(() => {
                setRecordToDelete(null);
                setIsDeleteModalOpened(false);
                setIsModalOpen(false);
            });
    };

    const updateRecordDetails = (field: string, ev: any, isBool = false) => {
        const updatedDetails = { ...recordDetails };
        updatedDetails[field] = isBool ? ev.target.checked : ev.target.value;
        setRecordDetails(updatedDetails);
    };

    const addNewRecord = () => {
        openRecordDetails(objectInitial);
    };

    const submitRecord = () => {
        setSaving(true);
        setErrMessage('');
        let objectId: any = null;
        if (recordDetails.id) {
            objectId = recordDetails.id;
        }
        const objectToSave = { ...recordDetails };
        notUpdateFields.forEach((field) => {
            delete objectToSave[field];
        });

        let promise = Promise.resolve({ data: null});
        if (objectId) {
            promise = http(true).patch(`${apiUpdate}/${objectId}`, { ...objectToSave });
        } else {
            promise = http(true).post(`${apiUpdate}`, { ...objectToSave });
        }


        promise
            .then(({ data }: { data: any }) => {
                if (data && data.status && data.status === 'ok') {
                    setIsModalOpen(false);
                    getRecords(currentPage, isActive);
                } else if (data && data.status && data.status === 'err') {
                    setErrMessage(`${data.message} [${data.records}]`);
                }
                // if (!objectId) {
                //     getRecords(currentPage, isActive);
                //     setRecordDetails(objectInitial);
                // }
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const handlePerPage = useCallback((event: any) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    }, []);

    const openRecordDetails = (record: any) => {
        setErrMessage('');
        setRecordDetails({ ...record });
        setIsModalOpen(true);
    };

    const downloadRecordFile = (record: any) => {
        const url = `${config.staticFilesURL}${record.path}`;
        window.open(url);
    };

    const isVisible = (key: string) => {
        return !(fieldsConfig && fieldsConfig[key] && fieldsConfig[key].hidden);
    };

    const isDisabled = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].disabled);
    };

    const getFieldModel = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].model) ? fieldsConfig[key].model : key;
    };

    const getLabel = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].label) ? fieldsConfig[key].label : key;
    };

    const getFieldType = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].type) ? fieldsConfig[key].type : key;
    };

    const getSize = (key: string) => {
        return (fieldsConfig && fieldsConfig[key] && fieldsConfig[key].size) ? fieldsConfig[key].size : 4;
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={8}>
                                    <h3>{pageHeader}</h3>
                                </Grid>
                                <Grid item md={4}>
                                    {objectInitial && <Button
                                        onClick={addNewRecord}
                                        variant="contained"
                                    >Dodaj</Button>}
                                </Grid>
                            </Grid>
                            <Table size="small">
                                <TableHeadStyled>
                                    <TableRow>
                                        {headers.map((header: any, ind: number) => {
                                            return (
                                                <TableCell className={header.className} key={ind}>
                                                    {header.label}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHeadStyled>
                                <TableBody>
                                    {records.length > 0 && records.map((record: any, indR) => {
                                        return (
                                            <TableRow key={indR}>
                                                {headers.map((header: any, indH: number) => {
                                                    return (
                                                        <TableCell key={indH}>
                                                            <Box sx={{ display: 'flex' }}>

                                                                {Array.isArray(header.actions) && header.actions.map((action: string, i: number) => {
                                                                    return (
                                                                        <Btn key={i} sx={{ mr: '5px' }}>
                                                                            { action === 'delete' && <DeleteIcon onClick={() => openDeleteDialog(record)} />}
                                                                            { action === 'edit' && <EditIcon onClick={() => openRecordDetails(record)}/>}
                                                                            { action === 'download' && <DownloadIcon onClick={() => downloadRecordFile(record)}/>}
                                                                        </Btn>
                                                                    );
                                                                })}

                                                                {!Array.isArray(header.actions) && !header.component && record[header.key]}

                                                                {!Array.isArray(header.actions) && header.component && <Box>
                                                                    {header.component === 'rating' && <Box>
                                                                        <Rating value={record[header.key]} max={6} readOnly />
                                                                    </Box>}
                                                                    {header.component === 'boolean' && <Box>
                                                                        {record[header.key] > 0 && <CheckCircleIcon sx={{ color: 'green' }} />}
                                                                        {record[header.key] === 0 && <CancelIcon sx={{ color: 'red' }} />}
                                                                    </Box>}
                                                                </Box>}
                                                            </Box>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <TablePagination
                component={'div'}
                count={total}
                page={currentPage}
                onPageChange={(ev, nextPage) => setCurrentPage(nextPage)}
                rowsPerPage={perPage}
                onRowsPerPageChange={handlePerPage}
            />

            <QuestionDialog
                question="Czy na pewno chcesz usunąć ten wpis?"
                opened={isDeleteModalOpened}
                onConfirm={deleteRecord}
                onClose={closeDeleteDialog}
            />

            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <CardStyled>
                    {recordDetails.id && <CardHeader title={`Edycja wpisu (ID: ${recordDetails && recordDetails.id})`} />}
                    {!recordDetails.id && <CardHeader title={`Dodawanie wpisu`} />}

                    <CloseLink><ClearIcon fontSize={'large'} onClick={() => setIsModalOpen(false)} /></CloseLink>
                    <CardContent>
                        <Grid container spacing={2}>
                            {recordDetails && Object.keys(recordDetails)
                                .filter((k) => isVisible(k))
                                .map((k, index) => {
                                    return (
                                        <Grid item md={getSize(k)} key={index}>
                                            {getFieldType(k) === 'objectId' && <ObjectInput
                                                onChange={(cityId) => updateRecordDetails(k, { target: { value: cityId }})}
                                                label={getLabel(k)}
                                                model={getFieldModel(k)}
                                                objectId={recordDetails[k]} />}
                                            {getFieldType(k) === 'switch' && <SwitchWrapper><Switch
                                                onChange={(ev) => updateRecordDetails(k, ev, true)}
                                                checked={!!recordDetails[k]} /> <InputLabel>{getLabel(k)}</InputLabel></SwitchWrapper>}
                                            {getFieldType(k) === 'text' && <TextField
                                                label={getLabel(k)}
                                                onChange={(ev) => updateRecordDetails(k, ev)}
                                                disabled={notUpdateFields.includes(k) || isDisabled(k)}
                                                fullWidth size={'small'}
                                                value={recordDetails[k] || ''} />}
                                            {getFieldType(k) === 'textarea' && <TextField
                                                label={getLabel(k)}
                                                multiline
                                                minRows={2}
                                                onChange={(ev) => updateRecordDetails(k, ev)}
                                                disabled={notUpdateFields.includes(k) || isDisabled(k)}
                                                fullWidth size={'small'}
                                                value={recordDetails[k] || ''} />}
                                            {getFieldType(k) === 'point' && <InputLabel>{getLabel(k)}: {recordDetails[k].coordinates.join(', ')}</InputLabel>}
                                        </Grid>
                                    )
                                })}

                            <Grid item md={12}>
                                <Button variant={'contained'} onClick={() => submitRecord()}>Zapisz</Button>
                                {recordDetails.id && apiDelete && <Button variant={'contained'} sx={{ ml: '20px' }} onClick={() => openDeleteDialog(recordDetails)}>Usuń</Button>}
                                <Button variant={'outlined'} sx={{ ml: '20px' }} onClick={() => setIsModalOpen(false)}>Zamknij</Button>
                                {saving && <span style={{ marginLeft: '20px' }}>Zapisuję zmiany...</span>}
                            </Grid>
                            {errMessage && <Grid item md={12} sx={{ color: 'red', fontWeight: 'bold' }}>
                                {errMessage}
                            </Grid>}
                        </Grid>
                    </CardContent>
                </CardStyled>
            </Modal>
        </>
    );
};
export default CrudList;

// styles
const CardContentStyled = styled(CardContent)({
    '.MuiGrid-item': {
        paddingTop: 5,
    },
    '.MuiFormLabel-root': {

    },
    p: {
        marginTop: 0,
        marginBottom: 0,
    },
});

const Btn = styled(Box)({
    cursor: 'pointer',
});

const CardStyled = styled(Card)({
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    overflowY: 'auto',
});

const SwitchWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const CloseLink = styled('div')({
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
});
