const commonConfig = {
    version: '0.1.0',
};

type EnvConfig = {
    version?: string;
    name?: string;
    apiUrl: string;
    staticFilesURL: string;
};

const config: EnvConfig = {
    ...commonConfig,
    apiUrl: process.env.REACT_APP_API_URL || '',
    staticFilesURL: process.env.REACT_APP_STATIC || '',
};

export default config;