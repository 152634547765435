import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import http from '../../Api/http';
import { BeatLoader } from 'react-spinners';

const CityInput = (
    { label, cityId, onChange }:
        { label: string; cityId?: number | null, onChange: (cityId: number) => void }
) => {
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [preselectedValue, setPreselectedValue] = useState<any>(null);
    const [isApiLoading, setIsApiLoading] = useState<boolean>(false);

    useEffect(() => {
        if (cityId) {
            setIsApiLoading(true);
            onSearch('', cityId);
        }
    }, []);

    const onSearch = (query: string = '', id?: number) => {
        if (query.length >= 3 || id) {
            http(true).get(`/cities?query=${query}${id ? `&id=${id}` : ''}`)
                .then(({data}) => {
                    setSearchResults((data && data.content) || []);
                    if (id && data && data.content && data.content[0]) {
                        setPreselectedValue(data.content[0]);
                    }
                })
                .finally(() => setIsApiLoading(false));
        }
    };

    const onSearchSelect = (ev: any, value: any) => {
        if (value && value.id) {
            onChange(value.id);
        }
    };

    return (
        <>
            {isApiLoading && <BeatLoader />}
            {!isApiLoading && <Autocomplete
                freeSolo
                // clearOnEscape={false}
                // clearOnBlur={false}
                value={preselectedValue}
                onInputChange={(event, newValue) => {
                    onSearch(newValue);
                }}
                onChange={(ev, values) => onSearchSelect(ev, values)}
                options={searchResults}
                filterOptions={(options) => options}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        size={'small'}
                        label={label}
                    />
                }
                renderOption={(props: any, option) => {
                    return (
                        <li {...props} key={option.id}>
                            <span>{option.name} ({option.provinceName})</span>
                        </li>
                    );
                }}
            />}
        </>
    )
};
export default CityInput;