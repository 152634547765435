import React from 'react';
import Dashboard from '../components/Layout/Dashboard';
import CrudList from '../components/CrudList/CrudList';

const LoginsIndex = () => {
    const headers = [
        { key: 'User.firstName', label: 'Imię', className: '' },
        { key: 'User.lastName', label: 'Nazwisko', className: '' },
        { key: 'registerIp', label: 'Adres IP', className: '' },
        { key: 'User.id', label: 'ID użytkownika', className: '' },
        { key: 'createdAt', label: 'Data', className: '' },
    ];

    return (
        <Dashboard>
            <CrudList
                pageHeader="Zapis logowania użytkowników"
                headers={headers}
                apiIndex="/logins"
                apiUpdate="/logins"
                apiDelete="/logins"
                pageSize={25}
            />
        </Dashboard>
    );
};
export default LoginsIndex;
