import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/Layout';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import http from '../Api/http';
import { login } from '../store/actions';
import { Box, TextField, Button, Grid, Card, CardContent, CardHeader } from '@mui/material';
import styled from '@emotion/styled';

const CardHeaderStyled = styled(CardHeader)({
    textAlign: 'center',
    paddingBottom: 0,
    paddingTop: 20,
});

const LoginPage = () => {
    const location = useLocation();
    let from = location.state?.from?.pathname || '/';
    const isAuth = useSelector((state: any) => state.isAuth);
    const navigate = useNavigate();
    const emailInputRef = useRef<HTMLInputElement>();
    const passwordInputRef = useRef<HTMLInputElement>();
    const dispatch = useDispatch();

    if (isAuth) {
        return <Navigate to="/" />;
    }

    const loginHandler = (event: any) => {
        event.preventDefault();

        const email = emailInputRef?.current?.value;
        const password = passwordInputRef?.current?.value;

        http().post('/auth/login', { email, password })
            .then(({ data }) => {
                dispatch(login(data.token));
                setTimeout(() => navigate(from), 100);
            })
            .catch((error) => {
                // TODO: handle error displaying
                console.log('error: ', error);
            });
    };

    return (
        <Layout>
            <Grid container>
                <Grid item md={3}></Grid>
                <Grid item md={6} sx={{ pt: 5 }}>
                    <Card>
                        <CardHeaderStyled title={'Logowanie'} />
                        <CardContent>
                            <Box component="form" onSubmit={(event: any) => loginHandler(event)} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    inputRef={emailInputRef}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Adres e-mail"
                                    name="email"
                                    autoComplete="email"
                                    type="email"
                                    autoFocus
                                    size={'small'}
                                />
                                <TextField
                                    margin="normal"
                                    inputRef={passwordInputRef}
                                    required
                                    fullWidth
                                    name="password"
                                    label="Hasło"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    size={'small'}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Zaloguj się
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
        </Layout>
    );
};
export default LoginPage;