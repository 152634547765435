import * as actionTypes from './actions';
import type IUser from '../types/IUser';

type InitialState = {
    user?: IUser | null;
    token?: string | null;
    isAuth: boolean;
    currentCalled?: boolean;
};

const initialState: InitialState = {
    user: null,
    token: null,
    isAuth: false,
    currentCalled: false,
};

const rootReducer = (state: InitialState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                user: action.user,
                token: action.token,
                isAuth: action.isAuth,
                currentCalled: action.currentCalled,
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                user: null,
                token: null,
                isAuth: false,
                currentCalled: true,
            };
        default:
            return state;
    }
};

export default rootReducer;